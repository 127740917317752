import {useTranslation} from '@pancakeswap/localization'
import styled from 'styled-components'
import Image from 'next/legacy/image'
import {Box, Text, Flex, CopyButton} from '@pancakeswap/uikit'
import {TuDogInfo} from 'views/Swap/hooks/fetchTuDog'
import {useMemo} from 'react'
import Ad from './images/ad.png'

const Container = styled(Box)`
  width: 75px;
  border-radius: 10px;
  overflow: hidden;
`

const TokenImg = styled(Box)`
  width: 100%;
  height: 75px;
  position: relative;
`

const Cell = styled(Flex)<{ $isBlack?: boolean }>`
  width: 100%;
  height: 20px;
  padding: 0 5px;
  align-items: center;
  background-color: ${({$isBlack, theme}) => $isBlack ? `${theme.colors.tuDogItem1}` : `${theme.colors.tuDogItem2}`};
`

const getStatusColor = (type, color) => {
  switch (type) {
    case 'Listed':
      return '#D5CE1F'
    case 'Preheating':
      return '#D50000'
    case 'Pre-sale':
      return '#0DB0F5'
    case 'Pre-market':
      return '#10CC23'
    default:
      return color
  }
}

const Status = styled(Text)<{ $type: string }>`
  font-size: 10px;
  color: ${({$type, theme}) => getStatusColor($type, theme.colors.text)};
`

interface TuDogItemProps {
  item: TuDogInfo
  onClick: () => void
}

const TuDogItem: React.FC<TuDogItemProps> = ({item, onClick}) => {
  const {t} = useTranslation()
  const adr = useMemo(() => {
    if (item.isNull) return null
    return `${item.address.substring(0, 3)}...${item.address.substring(item.address.length - 4)}`
  }, [item])


  return (
    <Container onClick={onClick}>
      <TokenImg>
        {item.isNull && !item.logo ? (
          <Image key="Token-isNull" src={Ad} layout="fill" placeholder="blur" alt="Img"/>) : (
          <Image key={item.logo} src={item.logo} blurDataURL={item.logo} layout="fill" placeholder="blur" alt="Img"/>
        )}
      </TokenImg>
      <Cell $isBlack justifyContent="center">
        <Text fontSize="12px" ellipsis>{item.symbol}</Text>
      </Cell>
      <Cell justifyContent="space-between">
        {adr && (
          <>
            <Text fontSize="10px">{adr}</Text>
            <CopyButton
              width="10px"
              buttonColor="tuDogText2"
              text={item.address}
              tooltipMessage={t('Token address copied')}
            />
          </>
        )}
      </Cell>
      <Cell $isBlack justifyContent="center">
        <Status ellipsis $type={item.status}>{t(item.status ? item.status : 'Awaiting ads')}</Status>
      </Cell>

    </Container>
  )
}

export default TuDogItem