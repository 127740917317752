import {Link} from '@pancakeswap/uikit'
import Image from 'next/legacy/image'
import {memo} from 'react'
import * as S from './Styled'

const BannerItem = ({img, url = null}) => {
  const flag = Boolean(url ?? undefined)
  const isString = typeof img === 'string'
  return (
    <S.Wrapper>
      <S.Inner>
        {
          flag ? (<Link href={url} external>
              <Image src={isString ? img : img.src} alt="Banner"
                     key={isString ? img : img.src}
                     style={{width: "100%", height: "100%", borderRadius: "10px"}} layout="fill"
                     placeholder="blur" blurDataURL={isString ? img : img.blurDataURL}/>
            </Link>)
            :
            (<Image src={isString ? img : img.src} alt="Banner"
                    key={isString ? img : img.src}
                    style={{width: "100%", height: "100%", borderRadius: "10px"}} layout="fill"
                    placeholder="blur" blurDataURL={isString ? img : img.blurDataURL}/>)
        }
      </S.Inner>
    </S.Wrapper>
  )
}

export default memo(BannerItem)
