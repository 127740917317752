import useSWRImmutable from "swr/immutable";
import shuffle from 'lodash/shuffle'
import {ReactElement, useMemo} from 'react'
import BannerItem from '../BannerItem'
import {adImg1, adImg2} from '../images'


interface IBannerConfig {
  shouldRender: boolean
  banner: ReactElement
}


export const fetchBanner = async (): Promise<IBannerConfig[]> => {
  const response = await fetch(`${process.env.NEXT_PUBLIC_BANNER_LIST_URL}`, {
    method: "GET"
  })
  const res = await response.json()
  if (res.code === 200) {
    return res.rows.map(i => ({
      shouldRender: true,
      banner: <BannerItem img={i.imageUrl} url={i.redirectUrl}/>,
    }))
  }
  return Promise.reject(res?.msg ?? 'Error')
}

function useBanner() {
  return useSWRImmutable(['BannerList'], () => {
    return fetchBanner()
  }, {
    revalidateOnMount: true
  })
}

/**
 * make your custom hook to control should render specific banner or not
 * add new campaign banner easily
 *
 * @example
 * ```ts
 *  {
 *    shouldRender: isRenderIFOBanner,
 *    banner: <IFOBanner />,
 *  },
 * ```
 */
export const useMultipleBannerConfig = () => {

  const {data} = useBanner()

  return useMemo(() => {
    const NO_SHUFFLE_BANNERS: IBannerConfig[] = data ? [
      ...data
    ] : []
    const SHUFFLE_BANNERS: IBannerConfig[] = [
      {
        shouldRender: !data || data.length <= 0,
        banner: <BannerItem img={adImg1}/>,
      },
      {
        shouldRender: !data || data.length <= 0,
        banner: <BannerItem img={adImg2}/>,
      },
    ]
    return [...NO_SHUFFLE_BANNERS, ...shuffle(SHUFFLE_BANNERS)]
      .filter((bannerConfig: IBannerConfig) => bannerConfig.shouldRender)
      .map((bannerConfig: IBannerConfig) => bannerConfig.banner)
  }, [data])
}
