import styled from 'styled-components'
import {useTranslation} from '@pancakeswap/localization'
import {
  Box,
  ChartIcon,
  CopyButton,
  Flex,
  Link,
  LogoIcon,
  Text,
  TwitterIcon,
  TelegramIcon,
  IconButton,
  RefreshIcon, CloseIcon
} from '@pancakeswap/uikit'
import {useMemo, useState} from "react";
import useSWRImmutable from 'swr/immutable'
import {useActiveChainId} from 'hooks/useActiveChainId'
import {fetchTuDog} from 'views/Swap/hooks/fetchTuDog'
import {CHAIN_NAME_MAPPING} from 'config/constants/endpoints'
import TuDogItem from './TuDogItem'

const StyleTuDog = styled(Box)`
  width: 360px;
  padding: 16px;
  background-color: ${({theme}) => `${theme.colors.tuDogBackground}`};
  border-radius: 20px;
`

const Banner = styled(Box)`
  width: 100%;
  border-radius: 10px;
  overflow: hidden;
  margin-bottom: 22px;
`

function useTuDog(chainId: number, langCode: string) {

  return useSWRImmutable(chainId && langCode && ['tuDog', chainId, langCode], () => {
    return fetchTuDog(chainId, langCode)
  }, {
    revalidateOnMount: true
  })
}

const chunkedArray = (arr, size) => arr.reduce((acc, _, i) => (i % size ? acc : [...acc, arr.slice(i, i + size)]), []);


export default function TuDog(props) {
  const {t, currentLanguage} = useTranslation()
  const {chainId} = useActiveChainId()
  const [current, setCurrent] = useState(0)
  const [show,setShow] = useState(true)

  const {data, error, mutate} = useTuDog(chainId, currentLanguage.code)

  const list = useMemo(() => {
    if (data) return chunkedArray(data, 4)
    return null
  }, [data])

  const intro = useMemo(() => {
    if (!data) return null
    return data[current]
  }, [current, data])


  return list && show && (
    <StyleTuDog {...props}>
      <Flex mb="10px" alignItems="center" justifyContent="space-between">
        <Flex alignItems="center">
          <LogoIcon/>
          <Text ml="8px" bold small>{t('New listings')}</Text>
        </Flex>

        <Flex alignItems="center">
          <IconButton
            ml="4px"
            onClick={() => {
              mutate()
            }}
            variant="text"
            size="sm"
            style={{width: '20px'}}
            height="20px"
          >
            <RefreshIcon color="primary" width="16px" height="16px"/>
          </IconButton>

          <IconButton
            size="sm"
            style={{width: '20px'}}
            height="20px"
            variant="text"
            ml="4px"
            onClick={() => {
              setShow(false)
            }}
          >
            <CloseIcon  color="primary" width="16px" height="16px" />
          </IconButton>
        </Flex>
      </Flex>

      {intro && !intro?.isNull && (
        <Banner>
          <Flex p="5px" alignItems="center" justifyContent="space-between" backgroundColor="tuDogCard1">
            <Text fontSize="12px" style={{flex: 1}}>{intro.symbol}</Text>
            <Link external
                  href={`https://m.avedex.cc/token/${intro.address}-${CHAIN_NAME_MAPPING[intro.chainId]}?utm_source=MuskSwap`}>
              <ChartIcon width="20px" color="secondary"/>
            </Link>
            <Text mx="8px"
                  fontSize="12px">CA: {`${intro.address.substring(0, 4)}...${intro.address.substring(intro.address.length - 6)}`}</Text>
            <CopyButton
              width="12px"
              buttonColor="textSubtle"
              text={intro.address}
              tooltipMessage={t('Token address copied')}
            />
          </Flex>
          <Box p="5px" backgroundColor="tuDogCard2">
            <Text small style={{whiteSpace: 'pre-line'}}>{intro.intro || '--'}</Text>
          </Box>

          <Flex p="5px" alignItems="center" justifyContent="space-between" backgroundColor="tuDogCard1">
            <Flex alignItems="center" width="40%">
              {intro.twitter && (<>
                <TwitterIcon color="textSubtle" width="16px"/>
                <Text ml="6px" mr="4px" fontSize="10px" ellipsis>{intro.twitter}</Text>
                <CopyButton
                  width="12px"
                  buttonColor="textSubtle"
                  text={intro.twitter}
                  tooltipMessage={t('Copied')}
                />
              </>)}
            </Flex>
            <Flex alignItems="center" width="40%" justifyContent="flex-end">
              {intro.telegram && (<>
                <TelegramIcon color="textSubtle" width="16px"/>
                <Text ml="6px" mr="4px" fontSize="10px" ellipsis>{intro.telegram}</Text>
                <CopyButton
                  width="12px"
                  buttonColor="textSubtle"
                  text={intro.telegram}
                  tooltipMessage={t('Copied')}
                />
              </>)}
            </Flex>
          </Flex>
        </Banner>
      )}

      {list.map((item, key) => (
        // eslint-disable-next-line react/no-array-index-key
        <Flex key={key} mb="12px" alignItems="center" justifyContent="space-between">
          {item.map((i, k) => (
            <TuDogItem key={i.address} item={i} onClick={() => {
              if (!i.isNull) {
                setCurrent(key * 4 + k)
              }
            }}/>
          ))}
        </Flex>
      ))}


      <Text color="tuDogText1"
            fontSize="10px">*{t('The above content is for inquiry and understanding only and is not intended as investment advice. Cryptocurrency transactions fluctuate greatly, please control the risks yourself. Profits and losses have nothing to do with this page.')}</Text>


    </StyleTuDog>
  )
}